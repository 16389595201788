<i18n locale="th" lang="yaml" >
page.title : "Log File ของ Ticket"
page.header : "Log file สำหรับ Ticket {name}"
page.description : "มาจาก File {file}"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.header',{ name: ticket.ticket_no })"
			:description ="$t('page.description',{ file: file.filename })"/>
		<div class="log-container">
			<MyLoadingSpin v-if="loading"/>

			<div v-if="file.id" class="page-actions">
				<a-button icon="left"
					@click="goBack">
					{{$t('common.back')}}
				</a-button>
				<a-button @click="downloadFile">
					<DroneIcon class="anticon"/> Download File ต้นฉบับ
				</a-button>
			</div>
			<div v-show="status == 'done'" id="bokeh_plot"  class="bokeh-pane">
			</div>
		</div>

	</div>

</template>

<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import MyLoadingSpin from "@components/common/MyLoadingSpin.vue"
import fileDownload from 'js-file-download'
import ApiError from "@utils/errors/ApiError"
import DroneIcon from "@components/icon/DroneIcon.vue"
import Worker from "@src/worker/ticket_log_parser.worker.js"
import {TIMEOUT_FILE_DOWNLOAD} from "@utils/axiosUtil"

const worker = new Worker()
// import pako from "pako"
export default {
	components : {
		MyLoadingSpin ,DroneIcon
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title')
		}
	} ,
	data() {
		return {
			version : "2.2.1" ,
			loading : false ,
			status : null,
			ticket : [],
			file : [],
		}
	} ,
	created() {
		this.loading = true
		this.$loadScript("https://cdn.bokeh.org/bokeh/release/bokeh-"+this.version+".min.js").then(()=>{
			return this.$loadScript("https://cdn.bokeh.org/bokeh/release/bokeh-widgets-"+this.version+".min.js")
		}).then(()=> {
			return this.$loadScript("https://cdn.bokeh.org/bokeh/release/bokeh-tables-"+this.version+".min.js")
		}).then(()=>{
			this.fetchData()
		})
	} ,
	methods : {
		downloadFile() {
			this.showPageLoading(true)
			axios.get("/api/file/download",{
				responseType: 'blob' ,
				params : {file : this.file.file_key} ,
				timeout : TIMEOUT_FILE_DOWNLOAD
			}).then((response) => {
				fileDownload(response.data,this.file.filename);
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.hidePageLoading()
			})
		} ,
		goBack() {
			this.$open({name:'ticket/view',params : {id:this.ticket.id}})
		} ,
		fetchData() {
			const fileId = this.$route.params.id;
			if (!fileId) {
				return
			}
			this.loading = true
			this.clearBreadcrumbParams(['ticketNo','ticketId'])
			axios.get("/api/tickets/processed-file/"+fileId,{timeout : TIMEOUT_FILE_DOWNLOAD}).then((response) => {
				const data = response.data.data
				this.status = data.status
				this.ticket = data.ticket
				this.file = data.file

				this.addBreadcrumbParams({
					ticketNo : this.ticket.ticket_no,
					ticketId : this.ticket.id
				})
				if (this.status == 'done' && 'content' in data) {
					// const decodedContent = window.atob(data.content)
					// const charData = decodedContent.split('').map(x => x.charCodeAt(0));
					// const rawData = pako.inflate(new Uint8Array(charData),{to : "string"})
					// const json = JSON.parse(rawData)
					// window.Bokeh.embed.embed_item(json, "bokeh_plot")
					// this.loading = false

					worker.onmessage = ({data}) => {
						window.Bokeh.embed.embed_item(data, "bokeh_plot")
						this.loading = false
					}
					worker.postMessage(data.content)
				} else {
					this.loading = false
				}
			}).catch((error) => {
				this.fetchError(error)
				this.status = 'error'
				this.loading = false
			})
		}
	}
}
</script>

<style lang="less" scoped>
.log-container {
	width : 1200px !important;
	min-height : 350px;
	position : relative;
	margin : 0 auto;
	padding : 24px;
	background : @white;
	box-shadow : @shadow-2;
}
.bokeh-pane::v-deep {
	width : 100%;
	text-align : left;
	font-family: @font-family;
	font-size : 14px;
	h1 {
		margin-top : 32px;
		margin-bottom : 8px;
		font-family: @font-family-title;
		font-weight : 600;
		font-size : 1.25em;
	}
}
</style>
